/* || Navbar */
/* prevent overlap with body */
body {
  padding-top: 6em;
  /* background-color: #0a2fa63d; */
}
.bg-dark {
  background-color: #bdc5df !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: black;
}
.navbar-dark .navbar-brand {
  font-weight: bold;
}
.navbar-brand {
  padding-left: 1rem;
}
/* need to place navbar behind the shadow dom of amplify toast errors with z
index of 99 */
.my-navbar {
  z-index: 98;
}

/* || Landing Page Media */
.my-landing-media {
  color: #007bff;
}
.card-header {
  color: #007bff;
  font-weight: bold;
}

/* || Carousel */
.carousel li.slide .my-carousel {
  font-size: 1.1em;
}

/* || Dictionary header */
.form-inline .col > .row {
  padding-top: 0.5em;
}
.form-inline div:nth-child(2) {
  padding-left: 3em;
}

/* || Other */

.HighlightClass {
  background-color: yellow;
  padding: 0;
}

.pagination {
  padding: 0.5rem;
}

.loading {
  padding-top: 150px;
}

.anskeycontainer {
  padding-top: 15px;
}

.askcontainer {
  padding-top: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-style: double;
}

/* || Highlight */
.Highlight span {
  background-color: yellow;
}
.Underline span {
  text-decoration: underline;
}

/* || Worksheet  */
.close-button {
  color: #b81b0d;
}

.border-2 {
  border-width: 2px !important;
}

/* || headers */
h1 {
  color: #3d7bff;
}

/* || help */
.card-body {
  background-color: #bdc5df;
  color: black;
}

/* || spinner */
.my-spinner {
  color: #0d6efd;
}

/* || aws authenticator */
:root {
  --amplify-primary-color: #2d33cb;
  --amplify-primary-tint: #2d33cb;
  --amplify-primary-shade: #2d33cb;
}

/* || authenticator */

@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}
